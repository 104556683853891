import './App.css';
import logo from './assets/images/logo.svg';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function App() {
  return (
    <div className="App">
      <div className="wrapper">
        <div className="video-bg"></div>
        <div className="video">
          {/* <video muted loop autoPlay className="video-source">
            <source src={video}></source>
          </video> */}
          <iframe
            className="video-source"
            src="https://www.youtube.com/embed/B6BHgF3NbYQ?&loop=1&playlist=B6BHgF3NbYQ&mute=1&autoplay=1&enablejsapi=1&controls=0" //youtu.be/sxIVUQVuvmQ
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
        </div>

        <div className="header">
          <div className="header__wrapper">
            <img className="header__logo" src={logo} alt="" />
            <h1 className="header__text">
              Компания “Starlights” Приветствует Вас В Своем Сайте Для Дополнительных Информаций
            </h1>
            <div className="contact">
              <a href="tel:+998988005858">
                <FontAwesomeIcon icon={faPhone} />
              </a>
              <a href="https://www.instagram.com/starlights.uz?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href="https://t.me/STARLIGHTS_DIRECTOR">
                <FontAwesomeIcon icon={faTelegram} />
              </a>
              <a href="#!">
                <FontAwesomeIcon icon={faYoutube} />
              </a>
              <a href="#!">
                <FontAwesomeIcon icon={faEnvelope} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
